.logo {
  &__image {
    height: 40px;
    @media (min-width: 1025px) {
      height: 60px;
    }
    &--footer {
      height: 50px;
      @media (min-width: 1025px) {
        height: auto;
        width: 150px;
      }
    }
  }
}