@import '../../assets/scss/variable.scss';

.mobile-scroll-menu {
  overflow-x: auto;
  margin: 0 -10px;
  width: calc(100% + 20px);
  overflow-y: visible;
  &{
    scrollbar-width: none;
    scrollbar-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0;
    border: none;
  }
  &__inner {
    width: min-content;
    display: flex;
    padding: 0 10px;
    column-gap: 15px;
    border-bottom: 1px solid rgba(16, 24, 32, 0.2);
    min-width: 100%;
    @media (min-width: 801px) {
      padding: 0;
    }
  }
  &__button {
    padding: 0 0 8px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
    color: rgba(16, 24, 32, 0.6);
    transition: .3s;
    border-bottom: 2px solid transparent;
    margin-bottom: -1px;
    &:hover {
      transition: .3s;
      cursor: pointer;
      color: rgba(16, 24, 32, 0.6);
    }
    &--active {
      color: $blue;
      border-bottom: 2px solid $blue;
      transition: .3s;
      &:hover {
        color: $blue;
        cursor: default;
        transition: .3s;
      }
    }
  }
  @media (min-width: 801px) {
    width: auto;
    margin: 0;
  }
}