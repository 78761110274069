@import '../../assets/scss/variable.scss';

.catalog-search {
  &__open-modal-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 14px;
    background: $white;
    border: 1px solid $blue;
    border-radius: 10px;
    position: relative;
    z-index: 0;
  }
  &__open-modal-info {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  &__open-modal-city {
    font-size: 18px;
    line-height: 21px;
    color: $black;
  }
  &__open-modal-other {
    opacity: 0.6;
  }
  &__dot-separator {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: $black;
    vertical-align: middle;
    margin: 0 8px;
  }
  &__modal {
    display: none;
    position: fixed;
    z-index: 10;
    width: 100vw;
    // height: 100vh;
    height: 100%;
    background: $white;
    top: 0;
    left: 0;
    padding: 10px;
    flex-direction: column;
    row-gap: 20px;
    &--active {
      display: flex;
    }
  }
  &__modal-icon {
    margin-left: auto;
    stroke: $black;
  }
}
