@import '../../assets/scss/variable.scss';

.map {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 400px;
  border-radius: 30px;
  overflow: hidden;
  background-color: $white;
  aspect-ratio: 1/1;
  &--modal {
    position: fixed;
    z-index: 15;
    width: 100%;
    height: 100%;
    max-height: none;
    max-width: none;
    left: 0px;
    top: 0px;
    border-radius: 0px;
  }

  .ol-zoom {
    position: relative;
    float: right;
    top: 40%;
    height: 106px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
      width: 40px;
      height: 40px;
      gap: 10px;
    }
  }

  .ol-attribution {
    display: none;
  }

  &__element {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__icon-mobile-map-marker {
    width: 24px;
    height: 24px;
  }

  &__icon-arrow {
    width: 16px;
    height: 16px;
  }

  &__mobile-open-full-map {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    border: 1px solid rgba(16, 24, 32, 0.1);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%);
  }

  &__mobile-map-text {
    color: $dark-blue;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__button-full-screen {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15px;
    left: 17px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    @media (max-width: 800px) {
      top: 10px;
      left: 10px;
    }
  }

  .ol-zoom-in {
    border-radius: 50%;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
  }

  .ol-zoom-out {
    border-radius: 50%;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
  }

  .ol-mycustomlabel {
    width: 30px;
    height: 30px;
    font: bold 14px Arial;
    color: blue;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
}

:root,
:host {
  --ol-background-color: white;
  --ol-accent-background-color: #f5f5f5;
  --ol-subtle-background-color: transparent;
  --ol-partial-background-color: rgba(255, 255, 255, 0.75);
  --ol-foreground-color: #333333;
  --ol-subtle-foreground-color: #666666;
  --ol-brand-color: #00aaff;
}

.ol-overlay-container.ol-selectable:hover {
  z-index: 1;
}

.custom-marker {
  position: relative;
  cursor: default;
  display: flex;
  gap: 4px;
  width: 100%;
  border-radius: 30px;
  background: $green;
  color: $white;
  padding: 10px 20px;

  &:link {
    color: $white;
  }
  &:visited {
    color: $white;
  }
  &:hover {
    .custom-marker__placeholder {
      display: flex;
      width: 300px;
      min-height: 100px;

      @media (max-width: 800px) {
        display: none;
      }
    }

    .custom-marker__placeholder-box {
      display: flex;
      min-height: 100px;
      flex-direction: column;

      @media (max-width: 800px) {
        display: none;
      }
    }

    .custom-marker__rooms-quantity {
      display: block;
    }
    // @media (max-width: 800px) {
    //   position: static;
    //   .custom-marker__placeholder-box {
    //     position: fixed;
    //     bottom: 15px;
    //     // left: 50%;
    //     // transform: translateX(-50%);
    //   }
    // }
  }
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom: 0;
    border-top: 8px solid $green;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 200%;
    top: -100%;
    left: 0;
    z-index: -1;
  }

  &--orange {
    background: $orange;
    &:before {
      border-top: 8px solid $orange;
    }
  }

  &--gray {
    background: $gray;
    &:before {
      border-top: 8px solid $gray;
    }
  }

  &--blue {
    background: $blue;
    &::before {
      border-top: 8px solid $blue;
    }
  }

  &__rooms-quantity {
    display: none;
  }

  &__placeholder {
    position: absolute;
    display: none;
    width: 100%;
    background-color: $white;
    color: $black;
    top: -104px;
    left: 50%;
    border-radius: 10px;
    height: 100%;
    transform: translateX(-50%);
    border: 1px solid $green;

    &--mobile {
      display: flex;
      top: auto;
      bottom: 15px;
      max-width: 300px;
      max-height: 100px;
    }

    &--orange {
      .custom-marker__apartment {
        color: $orange;
      }
      border-color: $orange;
    }

    &--gray {
      .custom-marker__apartment {
        color: $gray;
      }
      border-color: $gray;
    }

    &--blue {
      .custom-marker__apartment {
        color: $blue;
      }
      border-color: $blue;
    }
  }

  &--dubler {
    position: static;
    display: flex;
    transform: none;
    top: 0;
  }

  &__placeholder-box {
    position: absolute;
    display: none;
    width: 100%;
    width: 300px;
    max-height: 270px;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    color: $black;
    background-color: transparent;
    gap: 7px;
    border-bottom: 6px solid transparent;
    overflow-y: scroll;

    &--mobile {
      display: flex;
      overflow-y: scroll;
      bottom: 15px;
      flex-direction: column;
      border-bottom: none;
    }

    // &--disabled {
    //   pointer-events: none;
    // }

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      border: none;
    }
  }

  &__placeholder-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 8px 10px;
    gap: 4px;
    :last-child {
      margin-top: auto;
    }
  }

  &__apartment {
    color: $green;
    font-size: 10px;
  }

  &__info {
    color: $gray;
    font-size: 14px;
  }

  &__price {
    color: $black;
    font-size: 16px;
    font-weight: 600;
  }

  &__placeholder-image {
    border-radius: 10px 0px 0px 10px;
    min-width: 100px;
    height: 100px;
  }
}
