@import '../../assets/scss/variable.scss';

.app-input {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &--modal {
    position: relative;
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    opacity: 0.6;
  }
  &__field {
    padding: 10px;
    width: 100%;
    background: $white;
    border: 1px solid $dark-blue;
    border-radius: 10px;
    @media (min-width: 960px) {
      padding: 10px 20px;
    }
    &--error {
      border: 1px solid $red;
    }
  }
  &__error {
    font-size: 14px;
    line-height: 16px;
    color: $red;
  }

  &__additional {
    font-size: 14px;
    line-height: 16px;
    color: $dark-blue;
  }

  &__calendar-birthday {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__calendar-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    border-radius: 30px 30px 0 0;
    background-color: $white;
    z-index: 111;
    padding: 10px;

    @media (min-width: 960px) {
      position: absolute;
      width: fit-content;
      height: fit-content;
      left: auto;
      top: 0;
      padding: 10px;
      z-index: 111;
      background-color: $white;
      border-radius: 10px;
      border: 1px solid $dark-blue;
      right: 0;

      &--passport {
        top: 0;
      }
    }
  }

  &__mini-calendar-modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
    background: rgba(16, 24, 32, 0.3);
    z-index: 110;
  }

  &__swipe-button {
    width: 50px;
    height: 6px;
    background: $black;
    opacity: 0.6;
    border-radius: 10px;
    margin: 0 auto;
    min-height: 6px;
  }

  &__calendar {
    position: relative;
    max-width: 400px;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    @media (min-width: 960px) {
      transform: none;
      margin: auto;
      left: auto;
      top: auto;
    }
  }

  &__icon {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 15px;
    top: 30px;
    @media (min-width: 960px) {
      position: absolute;
      right: 15px;
      top: 10px;
    }
  }

  &::placeholder {
    color: rgba(16, 24, 32, 0.6);
  }
}
