@import '../../assets/scss/variable.scss';


.city {
  width: 100%;
  &__carousel {
    padding-top: 30px;
    @media (min-width: 1025px) {
      padding-top: 50px;
    }
    .swiper-slide {
      width: 280px;
      height: 240px;
      border-radius: 30px;
      background-position: center center;
      background-size: cover;
      @media (min-width: 1025px) {
        width: auto;
        height: 300px;
      }
    }
  }
  &__link {
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
    padding: 20px;
    color: $black;
    transition: .3s;
    &:hover {
      transition: .3s;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
      color: $black;
    }
  }
  &__item-line {
    display: block;
    width: 140px;
    border-bottom: 1px solid $black;
    padding-top: 10px;
    @media (min-width: 1025px) {
      width: 200px;
    }
  }
  &__info {
    padding-top: 10px;
  }
  &__info-count {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    padding-right: 4px;
    @media (min-width: 1025px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
}