@import '../../assets/scss/variable.scss';


.advantages {
  width: 100%;
  &__title {
    text-align: center;
  }
  &__text {
    text-align: center;
    padding-top: 10px;
    @media (min-width: 1025px) {
      padding-top: 20px;
    }
  }
  &__carousel {
    margin-top: 30px;
    @media (min-width: 1025px) {
      margin-top: 50px;
    }
    .swiper-pagination {
      position: static;
      padding-top: 10px;
    }
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      opacity: .4;
      background: $black;
      vertical-align: middle;
      margin: 2px !important;
    }
    .swiper-pagination-bullet-active {
      background: $blue;
      width: 10px;
      height: 10px;
      opacity: 1;
    }
    .swiper-slide {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .swiper-wrapper {
      row-gap: 20px;
    
    }
  }
  &__icon {
    width: 100px;
    height: 100px;
    @media (min-width: 1025px) {
      width: 50px;
      height: 50px;
    }
  }
  &__carousel-title {
    padding-top: 15px;
  }
}