@import '../../assets/scss/variable.scss';

.proof-identity {
  &__title {
    // opacity: .8;
    margin-bottom: 20px;
    @media (min-width: 960px) {
      margin-bottom: 30px;
    }
  }
  &__photos-wrap {
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  &__photos {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (min-width: 1280px) {
      max-width: 485px;
      width: 100%;
    }
  }
  &__photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 60px;
    background: rgba(225, 237, 255, 0.2);
    border: 1px solid rgba(0, 45, 114, 0.3);
    border-radius: 20px;
    height: calc((100vw - 20px) / 3 * 2);

    position: relative;

    &--disabled {
      padding: 0;
    }

    @media (min-width: 768px) {
      max-width: 485px;
      width: 100%;
      height: 300px;
    }

    @media (min-width: 1280px) {
      padding: 0px 120px;
      &--disabled {
        padding: 0;
      }
    }
  }

  &__received-photo {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: fill;
  }

  &__camera-photo {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
  }

  &__take-Photo {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid $black;
    background-color: $white;
    transform: translateX(-50%);

    &:hover {
      opacity: 0.7;
      transition: 1ms ease-in-out;
    }
  }

  &__make-video {
    transition: background-color 0.3s ease;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid $black;
    background-color: $white;
    transform: translateX(-50%);

    &--active {
      &::after {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        width: 15px;
        height: 15px;
        background-color: $red;
        opacity: 0;
        animation: blink 1s infinite;
      }

      @keyframes blink {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }

  &__photo-passport {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  &__photo-icon {
    width: 100%;
    height: auto;
  }
  &__photo-default {
    width: 100%;
    height: auto;
    max-width: 250px;
  }
  &__make-photo-box {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__make-photo {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__flip-camera {
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    width: 90px;
    background-color: $white;
    border-radius: 20px;
    border: 1px solid $black;

    &:hover {
      opacity: 0.7;
      transition: 1ms ease-in-out;
    }
  }

  &__button-photo {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 65px;
    height: 20px;
    background-color: $white;
    border-radius: 20px;
    border: 1px solid $black;

    &:hover {
      opacity: 0.7;
      transition: 1ms ease-in-out;
    }
  }

  &__photos-description {
    font-size: 12px;
    line-height: 14px;
    opacity: 0.8;
    text-align: center;
  }
  &__photos-buttons-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  &__photos-buttons {
    width: 100%;
    @media (min-width: 768px) {
      white-space: nowrap;
      max-width: 238px;
    }
  }
  &__button {
    width: 100%;
    margin-top: 20px;
    @media (min-width: 768px) {
      width: auto;
      margin: 20px auto 0;
    }
    @media (min-width: 960px) {
      margin-top: 30px;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 20px;
    @media (min-width: 960px) {
      margin-top: 40px;
    }
  }
  &__text-title {
    margin-top: 20px;
    @media (min-width: 960px) {
      margin-bottom: 5px;
      margin-top: 40px;
    }
  }
  &__text-description {
    opacity: 0.8;
  }

  &__verified {
    border: 1px solid;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 5px;
  }

  &__verified-title {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__icon {
    width: 41px;
    height: 41px;
  }

  &__button-contactless {
    max-width: none;
    width: 100%;

    @media (min-width: 960px) {
      max-width: 200px;
    }
  }
}
