@import '../../assets/scss/variable.scss';

.review-item {
  width: 100%;
  height: 253px;
  background: $white;
  border: 1px solid $dark-blue;
  border-radius: 20px;
  padding: 10px;
  text-align: left;
  &__apartament-link {
    display: flex;
    column-gap: 10px;
    color: $black;
    transition: 0.3s;
    &:hover {
      transition: 0.3s;
      color: $black;
    }
  }
  &__apartament-image {
    width: 70px;
    height: 70px;
  }
  &__apartament-address {
    padding-top: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $blue;
  }
  &__raiting {
    padding-top: 10px;
    @media (min-width: 448px) {
      padding-top: 15px;
    }
  }
  &__link {
    display: block;
    color: $black;
    padding-top: 10px;
    &:hover {
      color: $black;
    }
    @media (min-width: 448px) {
      padding-top: 15px;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $black;
    opacity: 0.6;
  }
  &__text {
    padding-top: 4px;
    max-height: 100px;
    overflow: hidden;
    font-size: 14px;
    line-height: 16px;
    @media (min-width: 448px) {
      max-height: 80px;
      font-size: 16px;
      line-height: 19px;
    }
  }
  &__more {
    color: $blue;
  }

  &__apartament-photos {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  @media (min-width: 448px) {
    max-width: 428px;
    height: 269px;
    padding: 20px 30px;
  }
  &--full {
    width: 100%;
    max-width: none;
    height: auto;
  }
}
