@import '../../../assets/scss/variable.scss';

.month {
  display: flex;
  justify-content: center;
  gap: 5px;

  @media (max-width: 600px) {
    margin-bottom: 0;
  }

  &__container-date {
    background-color: $white;
    cursor: pointer;
  }

  &__date {
    z-index: 1;
    position: absolute;
    flex-direction: column;
    display: flex;
    gap: 5px;
    overflow-y: scroll;
    max-height: 200px;
    background-color: $white;
  }
}
