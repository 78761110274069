@import '../../assets/scss/variable.scss';

.modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 2000;
  overflow-y: auto;
  min-height: min-content;
  &--open {
    display: block;
  }
  &--photosMoodal {
    .modal__inner {
      max-width: 100%;
      border-radius: 0;
      height: 100%;
      max-height: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      border: none;

      @media (min-width: 1024px) {
        width: calc(100% - 80px);
        padding: 84px 40px 30px 40px;
        gap: 30px;
        overflow: hidden;
        max-height: calc(100% - 40px);
        // height: fit-content;
        border-radius: 30px;

        height: 100%;
      }
      @media (min-width: 1921px) {
        max-width: 1840px;
        max-height: 1040px;
      }
    }
  }
  &__wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
    background: rgba(16, 24, 32, 0.3);
    z-index: 2000;
  }
  &__inner {
    bottom: 0;
    background: $white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px 30px 0px 0px;
    padding: 10px 20px 20px;
    position: absolute;
    // bottom: 0;
    width: 100%;
    z-index: 2010;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    align-items: center;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    text-align: center;
    @media (min-width: 960px) {
      bottom: auto;
      padding: 30px 40px;
      gap: 20px;
      max-width: 657px;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
      border: 1px solid rgba(16, 24, 32, 0.1);
      border-radius: 30px;
      // height: 100%;
    }
    @media (min-width: 1921px) {
      bottom: auto;
      max-width: 1840px;
      max-height: 1040px;
    }
  }
  &__swipe-button {
    width: 50px;
    height: 6px;
    background: $black;
    opacity: 0.6;
    border-radius: 10px;
    margin: 0 auto;
    @media (min-width: 960px) {
      display: none;
    }

    &--detail_modal {
      display: none;
    }
  }

  &__close_content {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;

    @media (min-width: 1024px) {
      &--mobail_modal_button {
        position: absolute;
        top: auto;
        right: auto;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
        top: 40px;
      }
    }
  }

  &__close-icon {
    width: 24px;
    height: 24px;
  }

  &__close-text {
    font-family: Roboto;
    font-size: 16px;
    color: $black;
    opacity: 0.8;
  }
}
