@import '../../assets/scss/variable.scss';

.history-booking-item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;
  gap: 10px;
  background: linear-gradient(0deg, rgba(39, 174, 96, 0.01), rgba(39, 174, 96, 0.01)), $white;
  border: 1px solid $green;
  border-radius: 20px;
  &__link {
    width: 100%;
    display: flex;
    gap: 10px;
    max-width: 368px;
  }
  &__image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  &__city {
    color: $black;
  }
  &__title {
    display: flex;
    flex-direction: column;
    gap: 4px;
    :hover {
      .history-booking-item__city {
        color: $black;
      }
    }
  }
  &__address {
    font-size: 14px;
    line-height: 16px;
    @media (min-width: 960px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  &__info-wrap {
    display: flex;
    gap: 20px;
    @media (min-width: 960px) {
      gap: 39px;
    }
    @media (min-width: 1024px) {
      gap: 10px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__description {
    font-size: 12px;
    line-height: 14px;
    opacity: .6;
    white-space: nowrap;
  }
  &__text {
    white-space: nowrap;
  }
  &__review {
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 181px;
  }
  &__review-text {
    text-align: center;
    opacity: .6;
    font-size: 14px;
    line-height: 16px;
    @media (min-width: 960px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &--cancelled {
    background: linear-gradient(0deg, rgba(235, 87, 87, 0.01), rgba(235, 87, 87, 0.01)), $white;
    border: 1px solid $red;
  }
}