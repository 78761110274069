@import '../../assets/scss/variable.scss';

.weather {
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;
    padding: 10px 0 70px;
    @media (min-width: 960px) {
      padding: 20px 0 160px;
    }
  }
  &__head-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    @media (min-width: 768px) {
      margin-left: auto;
      flex-direction: row;
    }
  }
  &__city {
    @media (min-width: 540px) {
      margin-left: auto;
      width: auto;
    }
    @media (min-width: 960px) {
    }
  }
  &__date-button {
    width: 100%;
    @media (min-width: 540px) {
      max-width: 400px;
      margin: 0 auto;
    }
    @media (min-width: 768px) {
      max-width: none;
      width: auto;
      margin: 0;
    }
  }
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px;
    gap: 10px;
    @media (min-width: 1024px) {
      gap: 30px;
    }
  }
}