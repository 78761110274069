@import '../../../assets/scss/variable.scss';

.weekdays {
  position: relative;
  display: flex;
  justify-content: space-around;

  @media (max-width: 600px) {
    margin: 15px auto;
    width: 100%;
    max-width: 340px;
  }

  &__days {
    position: relative;
    color: $gray;
    width: 100%;
    height: 19px;
    font-size: 16px;
    display: flex;
    justify-content: center;

    @media (min-width: 960px) {
      width: 17px;
    }
  }
}
