@import '../../assets/scss/variable.scss';

.payment {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 100px;

  &__buttons-wrap {
    display: flex;
    gap: 13px;
    flex-direction: row;
  }

  &__pay-header {
    font-size: 26px;
    @media (min-width: 1280px) {
      font-size: 30px;
    }
  }

  &__info-box {
    position: relative;
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;
    background-color: $light-blue;
    border-radius: 30px;
    gap: 30px;
    justify-content: center;
    align-items: center;

    @media (min-width: 960px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    gap: 20px;

    label {
      display: flex;
      flex-direction: row-reverse;
      white-space: wrap;
      width: 100%;
      justify-content: space-between;
      padding: 10px;
    }

    p {
      font-size: 18px;
      line-height: 25px;
      @media (min-width: 1280px) {
        font-size: 24px;
      }
    }

    span {
      margin-left: 20px;
    }
  }

  &__pay-way {
    position: relative;
    width: 100%;
    height: 100%;
    background: $white;
    border-radius: 30px;
    align-items: center;
    padding: 15px;

    @media (min-width: 1280px) {
      padding: 25px;
      max-width: 570px;
    }
  }

  &__panel {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &__button-purchase {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  &__checkbox {
    justify-content: center;
  }

  &__description {
    font-size: 12px;
    line-height: 14px;
    color: $black;
    opacity: 0.6;
    max-width: 300px;
    text-align: center;

    &--full_width {
      width: 100%;
    }

    @media (min-width: 801px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__button {
    font-size: 20px;
  }
}
