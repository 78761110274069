@import '../../assets/scss/variable.scss';

.account {
  &__inner {
    width: 100%;
    padding-bottom: 70px;
    @media (min-width:1024px) {
      display: flex;
      gap: 30px;
      padding-bottom: 160px;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 1024px) {
      width: calc(100% - 314px);
      max-width: 1001px;
    }
  }
  &__right-side {
    display: none;
    @media (min-width: 1024px) {
      width: 314px;
      display: block;
    }
  }
  &--big_gap {
    @media (min-width: 960px) {
      gap: 40px;
    }
  }
}