@import '../../assets/scss/variable.scss';

.app-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  width: 100%;
  background: rgba(225, 237, 255, 0.3);
  border: 1px solid rgba(0, 45, 114, 0.2);
  border-radius: 30px;
  @media (min-width: 1024px) {
    width: calc((100% - 30px) / 2);
    padding: 20px 30px;
  }
}