.loading {
  &__carousel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 187px;
    height: 147px;
    background: white;
    border-radius: 30px;
    padding: 30px 40px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__icon {
    width: 49px;
    height: 49px;
    animation: spin 1.5s linear infinite;
    -webkit-animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
