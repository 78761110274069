@import '../../assets/scss/variable.scss';

.service-agreement {
  position: relative;
  width: 100%;
  border: 1px solid $dark-blue;
  border-radius: 30px;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 478px;
  height: 100%;
  padding: 20px;
  min-height: 440px;
  @media (min-width: 768px) {
    max-height: 800px;
  }
  &__image {
    width: 100%;
    height: 100%;
  }

  &__loading {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 50px;
    left: 50%;
    top: 50%;
  }

  &__tooltip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  #oferta {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
    max-height: 478px;
    @media (min-width: 768px) {
      max-height: 800px;
    }
  }
}
