.mobile-app {
  width: 100%;
  max-width: 876px;
  margin: 0 auto;
  &__title {
    text-align: center;
  }
  &__text {
    text-align: center;
    padding-top: 10px;
  }
  &__image-wrapper {
    padding-top: 30px;
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    column-gap: 20px;
    @media (min-width: 768px) {
      justify-content: center;
      column-gap: 100px;
      padding-top: 50px;
    }
  }
  &__phone-picture {
    width: auto;
    line-height: 0;
    max-width: 426px;
    width: max-content;
  }
  &__phone-image {
    width: 100%;
  }
  &__desktop-image {
    display: none;
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 30px;
      padding-top: 50px;
    }
  }
  &__mobile-image {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    row-gap: 20px;
    @media (min-width: 768px) {
      display: none;
    }
  }
  &__link {
    width: 100%;
    display: block;
    line-height: 0;
    @media (min-width: 768px) {
      width: 199px;
    }

    &:hover {
      transition: 0.3 ease-in-out;
      opacity: 0.7;
    }
  }
  &__image {
    height: 50px;
    @media (min-width: 768px) {
      width: 100%;
      height: auto;
    }
  }
  &__qr-wrapper {
    display: none;
    line-height: 0;
    @media (min-width: 768px) {
      display: block;
    }
  }
  &__qr {
    width: 100%;
    padding-top: 40px;
  }
  &__qr-title {
    text-align: center;
  }
}
