@import '../../assets/scss/variable.scss';

.apartament-payment {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $white;
  border: none;
  border-radius: 20px;
  color: $black;
  overflow: hidden;
  transition: 0.3s;
  position: relative;
  box-sizing: content-box;
  max-width: 850px;

  &:hover {
    transition: 0.3s;
    color: $black;
  }

  &__carousel {
    width: 100%;
    aspect-ratio: 298 / 200;

    @media (min-width: 720px) {
      max-width: 298px;
      margin: 0;
      min-height: 298px;
      max-height: 100%;
    }

    .swiper-pagination {
      bottom: 5px;

      @media (min-width: 720px) {
        display: none;
      }
    }

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      opacity: 1;
      background: $white;
      vertical-align: middle;
      margin: 2px !important;
    }

    .swiper-pagination-bullet-active {
      background: $green;
      width: 10px;
      height: 10px;
      opacity: 1;
    }

    .swiper-slide {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      // max-height: 200px;

      @media (min-width: 720px) {
        overflow: hidden;
        max-height: 100%;
      }
    }
  }

  &__picture {
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__last-slide-more {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
  }

  &__square {
    display: flex;
    row-gap: 8px;
    column-gap: 20px;
    align-items: center;
    flex-wrap: wrap;
  }

  &__description {
    font-size: 12px;
    line-height: 14px;
    color: $black;
    opacity: 0.6;

    &--full_width {
      width: 100%;
    }

    @media (min-width: 801px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__info {
    display: flex;
    gap: 6px;
    flex-direction: column;
    padding: 10px;

    @media (min-width: 720px) {
      padding: 20px 15px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__date {
    color: $dark-blue;
    size: 16px;
  }

  &__price {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  &__discount {
    font-size: 28px;
  }
  &__total {
    font-size: 35px;
  }

  @media (min-width: 720px) {
    flex-direction: row;
    border-radius: 30px;
    align-items: stretch;

    &:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .swiper-pagination {
        display: flex;
        padding: 0 20px;
        align-items: center;
        column-gap: 10px;
      }

      .swiper-pagination-bullet {
        width: 100%;
        height: 4px;
        background: $light-blue;
        opacity: 0.6;
        border-radius: 5px;
        margin: 0 !important;
      }

      .swiper-pagination-bullet-active {
        background: $green;
        opacity: 1;
      }

      &.apartament-payment--partially_fits {
        .swiper-pagination-bullet-active {
          background: $orange;
        }
      }

      &.apartament-payment--fits_basic {
        .swiper-pagination-bullet-active {
          background: $gray;
        }
      }
    }
  }
}
