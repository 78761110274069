@import '../../assets/scss/variable.scss';

.booking-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  background: rgba(225, 237, 255, 0.5);
  border-radius: 10px;
  &__fields-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  &__group {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;
  }
  &__price {
    display: flex;
    width: 100%;
    justify-content: space-between;
    &--bonus {
      opacity: 0.6;
    }
  }
  &__checkbox {
    font-size: 14px;
    line-height: 16px;
  }
  &__button {
    width: 100%;
  }
  &__text {
    font-size: 14px;
    line-height: 16px;
    a {
      font-size: 14px;
      line-height: 16px;
    }
    &--center {
      text-align: center;
    }
    &--show_modal {
      color: $blue;
      transition: 3s;
      &:hover {
        cursor: pointer;
        color: $dark-blue;
        transition: 3s;
      }
    }
  }
  &__phone {
    width: 100%;
  }
  &__price-calculation {
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    justify-content: space-between;
    @media (max-width: 959px) {
      width: 100%;
    }
    @media (min-width: 960px) {
      gap: 20px;
    }
  }
  &__price-calculation-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    white-space: nowrap;
    @media (max-width: 959px) {
      width: 100%;
    }
  }
  &__price-calculation-title {
    font-size: 12px;
    line-height: 14px;
    opacity: 0.6;
    white-space: pre-wrap;
    @media (min-width: 960px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  @media (min-width: 481px) {
    max-width: 480px;
    margin: 0 auto;
    padding: 20px;
    gap: 20px;
    border-radius: 30px;
  }
  @media (max-width: 1023px) {
    margin-top: 10px;
  }
  @media (min-width: 1024px) {
    // padding: 20px;
    // gap: 20px;
    // border-radius: 30px;
  }
  &--side {
    position: sticky;
    top: 0;
  }
  &--hide {
    padding: 0;
    display: block;
    background: none;
    border-radius: 0;
  }

  &__reservation-buttons {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 10px;
    width: 100%;

    @media (min-width: 960px) {
      flex-direction: row;
    }
  }

  &__cancel-button {
    &--cancle_reservation {
      width: 100%;
    }
  }

  &__reservation-button-group {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  &__innings-button {
    background: $white;
    color: $dark-blue;
    &:hover {
      color: $white;
    }
  }

  &__loading {
    justify-content: center;
    display: flex;
    width: 100%;
    height: 110px;
    align-items: center;
  }

  &__date-error-info {
    color: $black;
  }

  &__refresh-code {
    color: $blue;
    cursor: pointer;
  }

  &__container-info-verifed {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__verifed-placholder {
    opacity: 0.6;
  }

  &__header-contacted-status {
    color: $black;
    &--status {
      color: $green;
    }
  }

  &__payment {
    &--payment_reservation {
      width: 100%;
    }
  }

  &__message {
    width: 100%;
    background: transparent;
    color: $dark-blue;

    &:hover {
      color: $white;
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
  }

  &__modal-button {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: column-reverse;

    @media (min-width: 960px) {
      flex-direction: row;
    }
  }
}
