@import '../../assets/scss/variable.scss';

.catalog {
  &__wrapper {
    display: flex;
    gap: 30px;
    padding: 20px 0 70px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
    flex-direction: column-reverse;

    @media (min-width: 1280px) {
      flex-direction: row;
    }
  }

  &__bread-crumbs {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    padding-top: 20px;
  }

  &__crumb-icon {
    width: 16px;
    height: 16px;
  }

  &__crumbs-text {
    opacity: 0.6;
  }

  &__carousel-box {
    position: fixed;
    background: rgba(16, 24, 32, 0.3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
  }

  &__carousel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 187px;
    height: 147px;
    background: $white;
    border-radius: 30px;
    padding: 30px 40px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__error {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 10px;
  }

  &__error-text {
    opacity: 0.8;
  }

  &__list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    max-width: 772px;
    width: 100%;

    @media (min-width: 1024px) {
      max-width: none;
    }

    @media (min-width: 1280px) {
      min-width: 772px;
      max-width: 772px;
    }
  }

  &__list-header {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;

    @media (min-width: 801px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__found-text {
    opacity: 0.8;
  }

  &__pagination {
    @media (min-width: 801px) {
      margin-top: 30px;
    }
  }

  &__full-mark {
    width: 100%;
    height: auto;
    overflow: hidden;
    aspect-ratio: 300/80;
    @media (max-width: 800px) {
      border-radius: 20px;
    }

    &.map {
      @media (max-width: 1020px) {
        .ol-zoom.ol-unselectable.ol-control {
          display: none;
        }
      }
      &--modal {
        position: fixed;
        z-index: 15;
        width: 100%;
        height: 100%;
        max-height: none;
        max-width: none;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        .ol-zoom.ol-unselectable.ol-control {
          display: flex;
        }
      }
    }

    @media (min-width: 1024px) {
      height: 400px;
    }

    @media (min-width: 1280px) {
      position: sticky;
      border-radius: 30px 0px 0px 30px;
      top: 0;
      height: 100vh;
      max-height: 900px;
    }
  }
}
