@import '../../assets/scss/variable.scss';

.catalog-detail {
  &__unfolding-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__unfolding-text {
    width: 100%;
  }
  &__unfolding-column {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    @media (min-width: 1335px) {
      max-width: calc((100% - 40px) / 3);
    }
  }
  &__inner {
    width: 100%;
    padding-bottom: 50px;
    @media (min-width: 1024px) {
      display: flex;
      gap: 30px;
      padding-bottom: 160px;
    }
  }
  &__content {
    width: 100%;
    @media (min-width: 1024px) {
      width: calc(100% - 314px);
      max-width: 1001px;
    }
  }
  &__right-side {
    display: none;
    @media (min-width: 1024px) {
      padding-top: 98px;
      width: 314px;
      display: block;
    }
  }
  &__interactive {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    gap: 10px;
    @media (min-width: 1024px) {
      gap: 20px;
    }
  }
  &__buttons-wrap {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    @media (min-width: 768px) {
      gap: 20px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    white-space: nowrap;
    align-items: center;
  }
  &__share {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    @media (min-width: 1024px) {
    }
  }
  &__share-modal {
    position: absolute;
    top: 30px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    border: 1px solid $dark-blue;
    border-radius: 30px;
    background: $white;
    width: fit-content;
    padding: 15px 20px;
    left: 0;
    max-width: 250px;

    @media (min-width: 766px) {
      padding: 20px 30px;
      left: auto;
      max-width: 340px;
    }
  }
  &__link {
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid $dark-blue;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__copy {
    width: 100%;
    opacity: 1;

    &:active {
      transition: 0.3 ease-in-out;
      opacity: 0.5;
    }
  }
  &__share-icon {
    opacity: 0.6;
    @media (min-width: 1024px) {
    }
  }
  &__text-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 30px;
    @media (min-width: 1024px) {
      padding-top: 40px;
      gap: 50px;
    }
  }

  &__carousel-box {
    position: fixed;
    background: rgba(16, 24, 32, 0.3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
  }

  &__carousel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 187px;
    height: 147px;
    background: $white;
    border-radius: 30px;
    padding: 30px 40px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
