@import '../../assets/scss/variable.scss';

.scrolling-buttons {
  overflow-x: auto;
  margin: 0 -10px;
  width: calc(100% + 20px);
  &{
    scrollbar-width: none;
    scrollbar-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0;
    border: none;
  }
  &__inner {
    width: min-content;
    display: flex;
    padding: 0 10px;
    column-gap: 4px;
    @media (min-width: 801px) {
      padding: 0;
    }
  }
  &__button {
    padding: 4px 10px;
    border-radius: 30px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
    color: $dark-blue;
    transition: .3s;
    &:hover {
      transition: .3s;
      cursor: pointer;
    }
    &--active {
      background: $light-blue;
      &:hover {
        cursor: default;
      }
    }
  }
  @media (min-width: 801px) {
    width: auto;
    margin: 0;
  }
}