@import './variable.scss';

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}
*:focus-visible {
  outline: none;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $black;
  @media (min-width: 801px) {
    font-size: 16px;
    line-height: 19px;
  }
}

img,
svg {
  line-height: 0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

h1 {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;

  @media (min-width: 801px) {
    font-size: 52px;
    line-height: 72px;
  }
}

h2 {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 120%;

  @media (min-width: 801px) {
    font-size: 40px;
    line-height: 55px;
  }
}

h3 {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  @media (min-width: 801px) {
    font-size: 24px;
    line-height: 33px;
  }
}

h4 {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;

  @media (min-width: 801px) {
    font-size: 20px;
    line-height: 28px;
  }
}

h5 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

a {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $blue;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
    color: $dark-blue;
  }
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button {
  background: none;
}

label {
  cursor: pointer;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(16, 24, 32, 0.2) transparent;
  }
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(16, 24, 32, 0.2);
  border-radius: 10px;
  border: none;
}

ul,
ol {
  padding-inline-start: 25px;
}
