@import '../../assets/scss/variable.scss';

.footer {
  padding: 30px 0;
  background: $dark-blue;
  @media (min-width: 1280px) {
    padding: 80px 0;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    @media (min-width: 1280px) {
      row-gap: 50px;
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: flex-start;
    @media (min-width: 600px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 30px;
    }
    @media (min-width: 1280px) {
      flex-direction: row;
      column-gap: 30px;
      flex-wrap: nowrap;
    }
    &--logo {
      row-gap: 30px;
      @media (min-width: 600px) {
        column-gap: 10px;
        justify-content: flex-start;
      }
      @media (min-width: 1280px) {
        align-items: center;
      }
    }
  }
  &__logo-box {
    max-width: 500px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    padding-left: 0;
    border-radius: 0 20px 20px 0;
    column-gap: 20px;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 500%;
      top: 0;
      left: -500%;
      background: $white;
    }
    @media (min-width: 768px) {
      column-gap: 125px;
    }
  }
  &__logo-box-text {
    color: $dark-blue;
    min-width: 170px;
    @media (min-width: 1280px) {
      min-width: 225px;
    }
  }
}
