.requisites {
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px) {
      gap: 40px;
    }
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 440px;
  }
}
