@import '../../assets/scss/variable.scss';

.link {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $blue;
  text-decoration: none;
  transition: .3s;
  &:hover {
    color: $dark-blue;
    transition: .3s;
  }
  &--footer {
    font-weight: 700;
    color: $white;
    opacity: .6;
    &:hover {
      color: $white;
      opacity: .8;
    }
  }
}