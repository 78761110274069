@import '../../assets/scss/variable.scss';

.guests {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__text {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    align-items: flex-start;
    color: $black;
  }
  &__note {
    font-size: 12px;
    line-height: 14px;
    color: $black;
    opacity: 0.6;
  }
  &__set-button {
    @media (min-width: 1025px) {
      display: none;      
    }
  }
  @media (min-width: 1025px) {
    row-gap: 30px;
  }
}