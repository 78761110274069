@import '../../assets/scss/variable.scss';

.chat {
  // position: fixed;
  // width: 100%;
  // height: 100%;
  // min-height: 400px;
  // background: white;
  // left: 0;
  // top: 0;
  // z-index: 11;

  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: $white;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 767px) {
    position: relative;
    min-height: 600px;
  }

  &__attach-icon {
    width: 33px;
    height: 33px;
  }

  &__file {
    position: relative;
    cursor: pointer;
  }

  &__file-input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__media-file {
    max-width: 350px;
    max-height: 300px;
  }

  &__media-img {
    width: 100%;
    height: 100%;
  }

  &__messages {
    height: 100%;
    position: relative;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;

    @media (min-width: 767px) {
      max-height: 500px;
      // padding: 10px;
      padding: 10px;
    }
  }

  &__message {
    background-color: $light-blue;
    color: $black;
    position: relative;
    padding: 10px;
    border-radius: 5px;
    gap: 10px;
    width: fit-content;
    max-width: 100%;
    word-wrap: break-word;

    &--mine {
      background-color: rgba(39, 174, 96, 0.1);
      color: $black;
      margin-left: auto;
    }
  }

  &__send {
    cursor: pointer;
    width: 33px;
    height: 33px;
  }

  &__loading-message {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;

    &--firstrender {
      // min-height: 500px;
    }
  }

  &__send-message {
    position: relative;
    width: 100%;
    display: flex;
    padding: 10px;
    background-color: #ffffff;
    // bottom: 0;
    align-items: center;
    gap: 10px;
    left: 0;
    bottom: 0;

    @media (min-width: 767px) {
      position: relative;
    }
  }

  &__write {
    width: 100%;
    border: 1px solid $blue;
    border-radius: 10px;
    padding: 10px;
  }

  &__back {
    width: 32px;
    height: 32px;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 0px;
    width: 100%;
    gap: 10px;
  }

  &__image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }

  &__preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }
  &__preview-title {
    display: flex;
    align-items: center;
    column-gap: 10px;
    row-gap: 4px;
    color: $black;
    @media (max-width: 1279px) {
      flex-wrap: wrap;
    }
  }
  &__preview-title-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__preview-title-info {
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.6;
    color: #101820;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__separator {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: $black;
    opacity: 0.6;
    border-radius: 50%;
    &:first-child {
      @media (max-width: 650px) {
        display: none;
      }
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 440px;
  }
}
