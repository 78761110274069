.header {
  padding: 15px 0;
  position: relative;
  z-index: 1;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (min-width: 1025px) {
    padding: 30px 0;
  }
}