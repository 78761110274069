@import '../../assets/scss/variable.scss';

.calendar-container {
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  flex-direction: column-reverse;
  gap: 15px;

  &__choose {
    width: 100%;
    max-width: 690px;
  }

  @media (min-width: 960px) {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
    overflow: visible;
    width: 100%;
  }

  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    min-width: 280px;
    margin: auto;
    overflow: scroll;
  }

  &__calendar {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;
    max-width: 690px;
    position: relative;

    @media (max-width: 600px) {
      max-width: 340px;
      flex-direction: column;
      justify-content: normal;
      align-items: center;
      margin: auto;
      gap: 0;
      overflow: scroll;

      & {
        scrollbar-width: none;
        scrollbar-color: transparent;
      }

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      &::-webkit-scrollbar-track {
        background: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0;
        border: none;
      }
    }

    @media (min-width: 960px) {
      max-width: 100%;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    stroke: $black;

    &:hover {
      stroke: $blue;
      transition: ease-in-out 300ms;
    }
  }

  &__button-arrow-left {
    position: absolute;
    left: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;

    &--disabled {
      display: none;
    }

    &--mobail {
      @media (max-width: 600px) {
        display: none;
      }
    }

    @media (min-width: 960px) {
      left: 0;
    }
  }

  &__button-arrow-right {
    position: absolute;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;

    &--disabled {
      display: none;
    }

    &--mobail {
      @media (max-width: 600px) {
        display: none;
      }
    }

    @media (min-width: 960px) {
      right: 0;
    }
  }

  &__button-group {
    justify-content: space-around;
    gap: 30px;

    @media (min-width: 960px) {
      padding-bottom: 0;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__button-reset {
    cursor: pointer;
    width: 100%;
    max-width: 155px;
    color: $dark-blue;
    font-size: 14px;
    display: none;

    @media (max-width: 600px) {
      max-width: 63px;
      height: 16px;
      font-size: 14px;
      display: none;
    }

    @media (min-width: 960px) {
      font-size: 20px;
      display: flex;
      flex-direction: column;
    }

    &--active {
      display: block;
    }
  }

  &__selected-date {
    p {
      font-weight: 600;
    }

    @media (max-width: 600px) {
      font-family: Rawline;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 28px;
      font-size: 20px;
      font-weight: 600;
      white-space: nowrap;
      text-align: center;
      align-items: center;
      margin: auto;
      max-width: 340px;
      margin-bottom: 15px;
    }

    @media (max-width: 960px) {
      font-family: Rawline;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 28px;
      font-size: 20px;
      font-weight: 600;
      white-space: nowrap;
      text-align: center;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  &__choice {
    display: flex;
    font-family: Rawline;
    align-items: center;
    font-size: 20px;
    height: 100%;
  }

  &__mobile-header {
    width: fit-content;

    @media (max-width: 600px) {
      background-color: $white;
      width: 100%;
    }

    @media (min-width: 960px) {
      width: 100%;
    }
  }
}
