@import '../../assets/scss/variable.scss';

.like {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 10px;
  font-size: 14px;
  line-height: 16px;
  transition: .3s;
  &__icon-wrap {
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 0;
  }
  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: $black;
    opacity: .6;
    z-index: 1;
    &--back {
      opacity: 1;
      fill: $white;
      z-index: 0;
    }
    &--active {
      opacity: 1;
      fill: $red;
    }
  }
  &:hover {
    cursor: pointer;
    transition: .3s;
    .like__icon {
      fill: $red;
      opacity: 1;
      &--back {
        opacity: 1;
        fill: $white;
      }
    }
  }
}