.favourites {
  &__not-found {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 10px;
  }

  &__not-found-text {
    opacity: 0.8;
  }

  &__pagination {
    margin: 0 auto;
    @media (min-width: 960px) {
      margin-top: 30px;
    }
  }

  &__scrolling-button {
    position: relative;
    display: flex;
    justify-content: right;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 440px;
  }
}
