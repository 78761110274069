.main-page-banner {
  position: relative;
  // z-index: -1;
  padding-top: 110px;
  padding-bottom: 58px;
  margin-top: -74px;
  text-align: center;
  @media (min-width: 1025px) {
    padding-top: 200px;
    margin-top: -124px;
    padding-bottom: 400px;
  }
  &__images {
    display: flex;
    column-gap: 10%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
  }
  &__picture {
    overflow: hidden;
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &--left {
      width: 45%;
      border-radius: 0px 0px 1000px 0px;      
    }
    &--right {
      margin-top: auto;
      border-radius: 1000px 0px 0px 0px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
    }
  }
  &__image {
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    object-position: center;
    object-fit: cover;
  }
}