@import '../../assets/scss/variable.scss';

.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  &__text {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    align-items: flex-start;
    color: $black;
  }
  &__note {
    font-size: 12px;
    line-height: 14px;
    color: $black;
    opacity: 0.6;
  }
  &__field {
    display: flex;
    column-gap: 20px;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: $black;
  }
  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: $light-blue;
    border-radius: 30px;
    transition: .3s;
    &:hover {
      transition: .3s;
      cursor: pointer;
      opacity: .7;
    }
    &--disable {
      opacity: .6;
      &:hover {
        cursor: default;
        opacity: .6;
      }
    }
  }
  &__icon {
    width: 18px;
    height: 18px;
    stroke: $dark-blue;
  }
}