@import '../../assets/scss/variable.scss';

.advantages-sticker {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    border: 1px solid $dark-blue;
    border-radius: 30px;
    font-size: 12px;
    line-height: 14px;
    color: $dark-blue;

    @media (min-width: 801px) {
      font-size: 14px;
      line-height: 16px;
    }
    &--clickable {
      &:hover {
        cursor: pointer;
      }
    }
    &--crossed {
      color: $gray;
      border-color: $gray;
      text-decoration: line-through;
    }
  }
}
