@import '../../assets/scss/variable.scss';

.unfolding-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 8px;
  background: rgba(36, 76, 116, 0.04);
  border-radius: 10px;
  min-width: 300px;
  width: 100%;
  &__title {
    font-size: 16px;
    font-weight: 600;
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  &__icon {
    width: 24px;
    height: 24px;
    fill: $black;
    opacity: 0.6;
  }
  &__button {
    color: $blue;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
  }
  @media (min-width: 640px) {
    max-width: calc((100% - 20px) / 2);
  }
  @media (min-width: 801px) {
    padding: 10px 20px;
    gap: 10px;
  }
  @media (min-width: 1335px) {
    max-width: 100%;
  }
}
