@import '../../assets/scss/variable.scss';


.range-slider {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;
  &__title {
    
  }
  &__field-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0px;
    row-gap: 8px;
    @media (max-width: 959px) {
      width: 100%;
    }
    @media (min-width: 960px) {
      max-width: 375px;
      row-gap: 10px;
    }
    @media (min-width: 1280px) {
      width: 375px;
    }
  }
  &__input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 10px;
  }
  &__field {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 8px 14px;
    min-width: 125px;
    min-height: 37px;
    border: 1px solid $blue;
    border-radius: 10px;
    column-gap: 4px;
  }
  &__input {
    -moz-appearance: textfield;
    background: transparent;
    width: 100%;
    font-size: 18px;
    line-height: 21px;
    color: $black;
    &input[type="number"]::-webkit-outer-spin-button,
    &input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
  &__field-placeholder {
    font-size: 18px;
    line-height: 21px;
    color: $black;
    opacity: 0.4;
  }
  &__separator {
    width: 16px;
    height: 2px;
    background: $black;
    opacity: 0.4;
    border-radius: 3px;
  }
  &__slider {
    position: relative;
    height: 30px;
    padding: 13.5px 0;
    margin-top: 8px;
  }
  &__slider-back {
    height: 3px;
    width: 100%;
    background: $black;
    opacity: 0.3;
  }
  &__progress-wrap {
    margin: 0 30px;
    position: relative;
  }
  &__progress {
    height: 3px;
    left: 25%;
    right: 25%;
    position: absolute;
    background: $blue;
    opacity: 1;
    z-index: 1;
    top: -3px;
  }
  &__range-wrapper {
    position: relative;
    margin: 0 15px;
  }
  &__range {
    position: absolute;
    width: 100%;
    height: 5px;
    top: -5px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    z-index: 1;
    &--left {
      left: -15px;
    }
    &--right {
      right: -15px;
    }
  }
  input[type="range"]::-webkit-slider-thumb {
    border-radius: 50%;
    pointer-events: auto;
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    background: $white;
    border: 3px solid $blue;
  }
  input[type="range"]::-moz-range-thumb {
    border-radius: 50%;
    pointer-events: auto;
    -moz-appearance: none;
    width: 30px;
    height: 30px;
    background: $white;
    border: 3px solid $blue;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}