@font-face {
    font-family: 'Roboto';
    font-display: swap;
    src: local('Roboto'), url("../fonts/roboto/roboto-regular.woff2") format('woff2'),
         url("../fonts/roboto/roboto-regular.woff") format('woff');
    font-weight: 400;
    font-style: normal;
}
// @font-face {
//     font-family: 'Roboto-semibold';
//     font-display: swap;
//     src: local('Roboto'), url("../fonts/roboto/roboto-semibold.woff2") format('woff2'),
//          url("../fonts/roboto/roboto-semibold.woff") format('woff');
//     font-weight: 600;
//     font-style: normal;
// }
@font-face {
    font-family: 'Roboto';
    font-display: swap;
    src: local('Roboto-bold'), url("../fonts/roboto/roboto-bold.woff2") format('woff2'),
         url("../fonts/roboto/roboto-bold.woff") format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Rawline';
    font-display: swap;
    src: local('Rawline'), url("../fonts/rawline/rawline-regular.woff2") format('woff2'),
         url("../fonts/rawline/rawline-regular.woff") format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Rawline';
    font-display: swap;
    src: local('Rawline'), url("../fonts/rawline/rawline-semibold.woff2") format('woff2'),
         url("../fonts/rawline/rawline-semibold.woff") format('woff');
    font-weight: 600;
    font-style: normal;
}