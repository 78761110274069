@import '../../../assets/scss/variable.scss';

.button-add {

    display: flex;
    overflow: auto;
    gap: 5px;

    :hover {
        transition: ease-in-out 300ms;
        border-color: $dark-blue;
        color: $dark-blue;
    }

    :active {
        transition: ease-in-out 300ms;
        background: $light-blue;
    }

    & {
        scrollbar-width: none;
        scrollbar-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0;
        border: none;
    }

    @media (max-width: 600px) {
        margin: auto;
        max-width: 375px;
    }

    @media (min-width: 900px) {
        gap: 20px;
    }

    &__days {
        cursor: pointer;
        color: $black;
        border: 1px solid $black;
        border-radius: 30px;
        font-size: 18px;
        white-space: nowrap;
        padding: 5px 10px;


        @media (max-width: 600px) {
            padding: 5px 10px;
            max-width: 90px;
            font-size: 18px;
        }

        @media (min-width: 960px) {
            padding: 10px 20px;
            font-size: 20px;
        }

    }
}