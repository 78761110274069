@import '../../assets/scss/variable.scss';

.header-links {
  display: flex;
  align-items: center;
  column-gap: 20px;
  &__submenu-wrapper {
    display: none;
    position: absolute;
    width: max-content;
    top: 42px;
    padding-top: 20px;
    right: -112px;
    @media (min-width: 900px) {
      right: auto;
    }
  }
  &__submenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 30px;
    gap: 20px;
    background: $white;
    border: 1px solid $dark-blue;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    width: 100%;
  }
  &__submenu-link{
    display: block;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #333333;
    width: 100%;
    text-transform: none;
    transition: .3s;
    &:first-letter{
      text-transform: uppercase
    }
    &:hover {
      transition: .3s;
      color: $blue;
      cursor: pointer;
    }
  }
  &__submenu-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #333333;
    text-transform: none;
    &:first-letter{
      text-transform: uppercase
    }
  }
  &__icon {
    width: 30px;
    height: 30px;
    fill: $dark-blue;
    @media (min-width: 601px) {
      width: 24px;
      height: 24px;
      fill: $black;
    }
    &.icon--stroke {
      stroke: $black;
      fill: none;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2px;
    text-transform: capitalize;
    text-decoration: none;
    color: $black;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    transition: .3s;
    &:hover {
      transition: .3s;
      cursor: pointer;
      color: $blue;
      .header-links__icon{
        fill: $blue;
      }
      .header-links__icon.icon--stroke {
        fill: none;
        stroke: $blue;
      }
    }
    &:hover .header-links__submenu-wrapper {
      display: flex;
    }
    &:last-child .header-links__submenu-wrapper {
      right: -10px;
      @media (min-width: 1400px) {
        right: -20px;
      }
      @media (min-width: 1500px) {
        right: -40px;
      }
      @media (min-width: 1681px) {
        right: auto;
      }
    }
    &--cart {
      display: block;
      padding: 8px 16px;
      background: $dark-blue;
      border-radius: 30px;
      color: $white;
      &:hover {
        color: $white;
      }
    }
  }
  @media (min-width: 768px) {
    column-gap: 50px;
  }
}