$white: #FFFFFF;
$dark-blue: #002D72;
$black: #101820;
$light-blue: #E1EDFF;
$blue: #0343A5;
$yellow: #FFC736;
$gray: #707479;
$green: #27AE60;
$orange: #F2994A;
$red: #EB5757;
$gray-light: #244C74 4%;

// :export {
//   theme-white: $white;
//   theme-dark-blue: $dark-blue;
//   theme-black: $black;
//   theme-light-blue: $light-blue;
//   theme-blue: $blue;
//   theme-yellow: $yellow;
//   theme-gray: $gray;
//   theme-green: $green;
//   theme-orange: $orange;
//   theme-red: $red;
//   theme-gray-light: $gray-light;
// }
