.security {
  &__field {
    @media (min-width: 960px) {
      text-align: right;
      flex-direction: row;
      align-items: center;
      column-gap: 30px;
      flex-wrap: wrap;
      .app-input__text , .radio-buttons__title {
        width: 100%;
        max-width: 284px;
        opacity: .4;
      }
      .app-input__field {
        width: 100%;
        max-width: calc(100% - 314px);
      }
      .app-input__error {
        margin-left: 314px;
      }
    }
  }
  &__checkbox {
    @media (min-width: 960px) {
      margin-left: 314px;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (min-width: 960px) {
      margin-left: 314px;
    }
  }
  &__button {
    width: 100%;
    @media (min-width: 768px) {
      max-width: 257px;
      margin-left: auto;
    }
    
  }
}