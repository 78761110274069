.reviews {
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 886px;
    margin: 0 auto;
    padding: 10px 0 70px;
    @media (min-width: 960px) {
      padding: 20px 0 160px;
    }
  }
  &__sort {
    @media (min-width: 960px) {
      margin-left: auto;
    }
  }
  &__reviews-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__pagination {
    margin: 0 auto;
    @media (min-width: 960px) {
      margin-top: 30px;
    }
  }
  &__loading {
    display: flex;
    justify-content: center;
    min-height: 500px;
    align-items: center;
  }
}
