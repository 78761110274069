@import '../../assets/scss/variable.scss';

.raiting {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 4px;
  &__reviews-count {
    margin-left: 6px;
    opacity: 0.6;
  }
  &__stars {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 4px;
  }
  &__gold-icon-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  &__icon {
    width: auto;
    max-width: 24px;
    height: 100%;
    fill: $black;
    opacity: .2;
    &--gold {
      opacity: 1;
      fill: $yellow;
    }
  }
  &__item {
    position: relative;
    width: 24px;
    height: 24px;
    &--clickable {
      &:hover {
        cursor: pointer;
        // .raiting__icon {
        //   opacity: .2;
        //     fill: $yellow;
        // }
      }
    }
  }
}