@import '../../../assets/scss/variable.scss';

.week {
    display: flex;
    width: 100%;
    gap: calc(((100% - (35px * 7)) / 6));
    // padding-top: 10px;

    @media (max-width: 400px) {
        padding-top: 8px;
        gap: calc(((100% - (35px * 7)) / 6));
    }

    @media (max-width: 600px) {
        gap: calc(((100% - (40px * 7)) / 6));
    }

    @media (min-width: 960px) {
        gap: 12px;
    }

    &--active {
        justify-content: flex-end;
    }

}