@import '../../assets/scss/variable.scss';

.catalog-filters {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 40px 10px 10px;
  background: rgba(225, 237, 255, 0.5);
  border-radius: 0px 0px 20px 20px;
  margin-top: -30px;

  &__checbox-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  @media (min-width: 960px) {
    flex-direction: row;
    column-gap: 50px;
    padding-top: 50px;

    &--mapModal {
      position: fixed;
      z-index: 101;
      bottom: 15px;
      // left: 50%;
      // transform: translateX(-50%);
      border-radius: 30px;
      background: $white;
      padding: 20px;
      max-width: 1114px;
      margin: 0 auto;
      left: 0;
      right: 0;

      .catalog-filters__price-slider {
        flex-wrap: nowrap;
        white-space: nowrap;
      }

      .catalog-filters__checbox-wrap {
        row-gap: 20px;
      }
    }
  }
}
