@import '../../assets/scss/variable.scss';

.catalog-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  width: 100%;
  &__carousel-box {
    position: fixed;
    background: rgba(16, 24, 32, 0.3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    z-index: 10000;
  }

  &__carousel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 187px;
    height: 147px;
    background: $white;
    border-radius: 30px;
    padding: 30px 40px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
