@import '../../../assets/scss/variable.scss';

.calendar {
    max-width: 320px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;


    @media (min-width: 960px) {
        max-width: 387px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__box {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__week {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}