@import '../../assets/scss/variable.scss';

.search {
  position: relative;
  &__fields-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    column-gap: 4px;
    @media (min-width: 960px) {
      flex-wrap: nowrap;
      row-gap: 0;
      column-gap: 0;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      column-gap: 3px;
      border: 3px solid $dark-blue;
      background: $dark-blue;
    }
  }
  &__button {
    width: 100%;
    min-height: 47px;
    font-size: 20px;
    line-height: 23px;
    margin-top: 6px;
    &--modal {
      margin-top: auto;
    }
    &:hover {
      cursor: pointer;
    }
    @media (min-width: 960px) {
      padding: 0px 40px;
      font-size: 24px;
      line-height: 28px;
      width: auto;
      min-width: 150px;
      min-height: 63px;
      margin-top: 0;
    }
  }
  &--modal {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}