@import '../../assets/scss/variable.scss';

.bonus-points {
  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    @media (min-width: 960px) {
      flex-direction: row;
      column-gap: 30px;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  &__text-title {
    @media (min-width: 960px) {
      margin-bottom: 5px;
    }
  }
  &__info {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    background: rgba(3, 67, 165, 0.03);
    border: 1px solid $blue;
    border-radius: 20px;
  }
  &__info-text {
    font-size: 12px;
    line-height: 14px;
    color: $blue;
    white-space: nowrap;
    @media (min-width: 960px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  &__info-count {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: $blue;
    @media (min-width: 960px) {
      font-size: 52px;
      line-height: 72px;
    }
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 440px;
  }
}
