@import '../../assets/scss/variable.scss';

.search-modal {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow-y: auto;
  min-height: min-content;
  width: 100vw;
  height: 100vh;

  &--city {
    padding: 14px 10px;
    background: $white;
    text-align: left;
    box-shadow: none;
    border-radius: 0;
    top: 0;
    bottom: auto;
  }

  &--open {
    display: block;
  }

  &__wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
    background: rgba(16, 24, 32, 0.3);
    z-index: 1000;

    @media (min-width: 960px) {
      display: none;
    }
  }

  &__inner {
    background: $white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px 30px 0px 0px;
    padding: 10px 20px 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1010;
    // max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    &--calendar {
      max-height: 85vh;
      padding: 10px;
      overflow: hidden;
    }

    @media (min-width: 960px) {
      position: static;
      border-radius: 0;
      box-shadow: none;
      padding: 20px 30px;
      overflow-y: visible;
    }
  }

  &__swipe-button {
    width: 50px;
    height: 6px;
    background: $black;
    opacity: 0.6;
    border-radius: 10px;
    margin: 0 auto;
    min-height: 6px;

    @media (min-width: 960px) {
      display: none;
    }
  }

  &__title {
    padding: 17px 32px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: $black;
    opacity: 0.6;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(16, 24, 32, 0.2);

    @media (min-width: 960px) {
      display: none;
    }
  }

  &__arrow {
    fill: none;
    stroke: $black;
    width: 16px;
    height: 16px;
    opacity: 0.8;
    border-radius: 16px;
    transform: rotate(-180deg);
    position: absolute;
    left: 8px;
    vertical-align: middle;
  }

  &__list {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 960px) {
      padding: 0;
    }
  }

  &__item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: $black;
    padding: 15px 32px;

    @media (min-width: 960px) {
      padding: 20px 30px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: $black;
      transition: 0.3s;

      &:hover {
        transition: 0.3s;
        background: $light-blue;
        color: $dark-blue;
        cursor: pointer;
      }
    }
  }

  @media (min-width: 960px) {
    position: absolute;
    left: auto;
    max-width: 100%;
    min-width: 230px;
    height: fit-content;
    border: 1px solid $dark-blue;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    top: calc(100% + 7px);
    padding: 0;
    overflow-x: auto;
    min-width: max-content;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  &--calendarMini {
    @media (min-width: 960px) {
      border-radius: 10px;
      max-width: fit-content;
      top: 45px;
      left: auto;
      z-index: 2;
    }
  }
  &--calendar {
    @media (min-width: 960px) {
      max-width: 884px;
    }
  }
}
