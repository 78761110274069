@import '../../assets/scss/variable.scss';

.modal-menu {
  display: none;
  background: $white;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  padding: 10px;
  overflow: scroll;
  &--active {
    display: block;
  }
  &__title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__close {
    width: 24px;
    height: 24px;
    stroke: $black;
  }
  &__list {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 15px;
  }
  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    fill: $black;
    &.icon--stroke {
      stroke: $black;
      fill: none;
    }
  }
  &__item {
    display: flex;
    flex-wrap: wrap;
    color: $black;
    align-items: center;
    text-transform: capitalize;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
  }
  &__arrow {
    width: 16px;
    height: 16px;
    fill: none;
    stroke: $black;
    margin-left: auto;
  }
  &__line {
    width: 100%;
    height: 1px;
    background: $black;
    opacity: .2;
  }
  &__submenu {
    padding-left: 34px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
  }
  &__submenu-link{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $black;
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-transform: none;
    &:first-letter{
      text-transform: uppercase
    }
  }
  &__submenu-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $black;
    text-transform: none;
    &:first-letter{
      text-transform: uppercase
    }
  }
}