@import '../../assets/scss/variable.scss';

.catalog-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $white;
  border: 3px solid $green;
  border-radius: 20px;
  color: $black;
  overflow: hidden;
  transition: 0.3s;
  position: relative;
  box-sizing: content-box;

  &__field {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
  }

  &__modal-button {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: column-reverse;

    @media (min-width: 960px) {
      flex-direction: row;
    }
  }

  &--fav {
    border-color: $dark-blue;
  }
  &--current {
    border-color: $dark-blue;
  }
  &__city-name {
    color: $dark-blue;
  }

  &:hover {
    transition: 0.3s;
    color: $black;
  }

  &__carousel {
    width: 100%;
    aspect-ratio: 298 / 200;

    @media (min-width: 720px) {
      max-width: 298px;
      margin: 0;
      min-height: 298px;
      max-height: 100%;
    }

    .swiper-pagination {
      bottom: 5px;

      @media (min-width: 720px) {
        display: none;
      }
    }

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      opacity: 1;
      background: $white;
      vertical-align: middle;
      margin: 2px !important;
    }

    .swiper-pagination-bullet-active {
      background: $green;
      width: 10px;
      height: 10px;
      opacity: 1;
    }

    .swiper-slide {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      // max-height: 200px;

      @media (min-width: 720px) {
        overflow: hidden;
        max-height: 100%;
      }
    }
  }

  &__picture {
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__last-slide-more {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 10px;
    row-gap: 8px;
    width: 100%;

    @media (min-width: 720px) {
      padding: 20px 15px;
      row-gap: 10px;
    }

    &--relocation {
      padding: 0;

      @media (min-width: 720px) {
        row-gap: 0;
        column-gap: 10px;
        padding: 0;
      }
    }
  }

  &__apartament {
    border-bottom: 1px solid rgba(16, 24, 32, 0.6);
    padding-bottom: 5px;
    overflow: hidden;

    .catalog-item__image {
      margin-bottom: 15px;
      aspect-ratio: 288 / 130;

      @media (min-width: 720px) {
        position: static;
        height: 140px;
        border-radius: 0px 0px 30px 30px;
        margin: 0;
        width: 100%;
        max-width: 298px;
        min-width: 298px;
      }
    }

    .catalog-item__raiting {
      margin-top: 4px;
    }

    @media (min-width: 720px) {
      display: flex;
      border-bottom: none;
      padding: 5px 0 0;
      column-gap: 10px;
      align-items: flex-end;
    }

    &--start {
      border-radius: 20px 20px 0px 0px;

      @media (min-width: 720px) {
        border-bottom: 1px solid rgba(16, 24, 32, 0.6);
        padding-top: 0;
        padding-bottom: 5px;
        border-radius: 0;
        align-items: flex-start;

        .catalog-item__image {
          border-radius: 30px 30px 0px 0px;
        }
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    @media (min-width: 720px) {
      row-gap: 6px;
    }
  }

  &__found-status {
    font-size: 12px;
    line-height: 14px;
    color: $green;

    @media (min-width: 720px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__address {
    @media (min-width: 720px) {
      font-size: 20px;
      line-height: normal;
    }
  }

  &__description {
    font-size: 12px;
    line-height: 14px;
    color: $black;
    opacity: 0.6;

    &--full_width {
      width: 100%;
    }

    @media (min-width: 801px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__square {
    display: flex;
    row-gap: 8px;
    column-gap: 20px;
    align-items: center;
    flex-wrap: wrap;
  }

  &__other {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: auto;

    @media (min-width: 720px) {
      align-items: flex-end;
    }
  }

  &__icons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    column-gap: 10px;
    min-height: 44px;
  }

  &__icons-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
    max-width: 126px;
    min-width: 60px;
  }

  &__icon {
    position: relative;
    width: 24px;
    height: 24px;
    opacity: 0.6;
    &--disable {
      opacity: 0.3;
    }
  }

  &__icon-crossed {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__more-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border: 1px solid $blue;
    border-radius: 30px;
    font-size: 14px;
    line-height: 16px;
    color: $blue;
  }

  &__price {
    white-space: nowrap;
  }

  &__price-for {
    font-size: 12px;
    line-height: 14px;
    color: $black;
    opacity: 0.6;
    text-align: right;

    @media (min-width: 801px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__date {
    @media (min-width: 720px) {
      display: flex;
      flex-direction: column;
      row-gap: 6px;
    }
  }

  &__like {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px;

    @media (min-width: 720px) {
      right: 30px;
    }

    &--booking {
      @media (min-width: 720px) {
        right: 275px;
      }
    }
  }

  &__booking {
    min-width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin: 0 10px;
    padding: 10px 0;

    @media (max-width: 719px) {
      border-top: 1px solid rgba(16, 24, 32, 0.2);
    }

    @media (min-width: 720px) {
      width: 100%;
      min-width: 260px;
      max-width: 260px;
      margin: 15px 0;
      padding: 5px 15px;
      border-left: 1px solid rgba(16, 24, 32, 0.2);
    }
  }

  &__booking-btn-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
    justify-content: center;
  }

  &__booking-request {
    &--active {
      color: $green;
    }
  }

  &__not-verifed-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__booking-date {
    margin-top: 6px;
  }

  &__booking-status {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 15px;
  }

  @media (min-width: 720px) {
    flex-direction: row;
    border-radius: 30px;
    align-items: stretch;

    &:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .swiper-pagination {
        display: flex;
        padding: 0 20px;
        align-items: center;
        column-gap: 10px;
      }

      .swiper-pagination-bullet {
        width: 100%;
        height: 4px;
        background: $light-blue;
        opacity: 0.6;
        border-radius: 5px;
        margin: 0 !important;
      }

      .swiper-pagination-bullet-active {
        background: $green;
        opacity: 1;
      }

      &.catalog-item--partially_fits {
        .swiper-pagination-bullet-active {
          background: $orange;
        }
      }

      &.catalog-item--fits_basic {
        .swiper-pagination-bullet-active {
          background: $gray;
        }
      }
    }
  }

  &--partially_fits {
    border-color: $orange;

    .catalog-item__found-status {
      color: $orange;
    }

    .swiper-pagination-bullet-active {
      background: $orange;
    }
  }

  &--fits_basic {
    border-color: $gray;

    .catalog-item__found-status {
      color: $gray;
    }

    .swiper-pagination-bullet-active {
      background: $gray;
    }
  }

  &--relocation {
    border-color: $blue;
    padding: 6px;

    .catalog-item__found-status {
      color: $blue;
    }

    .catalog-item__other {
      padding-top: 5px;

      @media (min-width: 720px) {
        padding: 15px 10px;
        border-left: 1px solid rgba(16, 24, 32, 0.6);
        flex-direction: column;
        align-items: start;
        min-width: 198px;
      }
    }

    .catalog-item__price-info {
      @media (min-width: 720px) {
        margin-left: auto;
      }
    }

    .catalog-item__title {
      @media (min-width: 720px) {
        padding: 15px 0;
      }
    }

    @media (min-width: 720px) {
      column-gap: 10px;
    }
  }
}
