@import '../../assets/scss/variable.scss';

.other-filters {
  width: 100%;

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    gap: 10px;
    border: 1px solid $dark-blue;
    border-radius: 30px;
    font-size: 18px;
    line-height: 21px;
    color: $dark-blue;
    width: 100%;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      cursor: pointer;
      border: 1px solid $blue;
      color: $blue;

      .catalog-filters__button-icon {
        fill: $blue;
      }
    }
  }

  &__button-icon {
    fill: $dark-blue;
    width: 24px;
    height: 24px;
  }

  &__modal {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100%;
    background: #fff;
    top: 0;
    left: 0;
    z-index: 1000;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 30px;
    row-gap: 30px;
    overflow: auto;

    &--open {
      display: flex;
    }

    @media (min-width: 1280px) {
      max-width: 1443px;
      max-height: 733px;
      width: 100%;
      height: 85%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid $dark-blue;
      border-radius: 30px;
      padding: 20px 30px;
      row-gap: 0;
    }
    @media (min-width: 1360px) {
      padding: 30px 50px;
    }

    @media (min-width: 1440px) {
      width: 95%;
    }

    @media (min-width: 1280px) {
      &--mapModalOpen {
        top: auto;
        bottom: 15px;
        transform: translate(-50%, 0);
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
  }

  &__text-title {
    @media (min-width: 1280px) {
      display: none;
    }
  }

  &__close {
    stroke: #101820;

    @media (min-width: 1280px) {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    row-gap: 30px;

    @media (min-width: 1280px) {
      flex-direction: row;
      overflow: hidden;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    row-gap: 30px;

    &:last-child {
      .other-filters__section {
        border-bottom: none;
        padding: 0;
      }
    }

    @media (min-width: 1280px) {
      width: 100%;
      overflow-y: auto;
      max-width: 314px;
    }
  }

  &__section {
    border-bottom: 1px solid rgba(16, 24, 32, 0.2);
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @media (min-width: 1280px) {
      border: none;
      padding-bottom: 0;
    }
  }

  &__filters {
    white-space: wrap;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    @media (min-width: 1280px) {
      flex-direction: row-reverse;
      column-gap: 20px;
      margin-top: 30px;
    }
  }

  @media (min-width: 1280px) {
    max-width: 250px;
    min-width: 250px;
    margin-left: auto;
  }
}
