@import '../../assets/scss/variable.scss';

.history-bookings {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;

  &__not-found {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 10px;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 440px;
  }

  &__not-found-text {
    opacity: 0.8;
  }

  &__pagination {
    margin: 0 auto;
    @media (min-width: 960px) {
      margin-top: 30px;
    }
  }
  &__city {
    @media (min-width: 960px) {
      margin-left: auto;
    }
  }
}
