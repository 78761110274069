@import '../../assets/scss/variable.scss';

.review {
  width: 100%;
  padding: 0 10px 70px;
  &__title {
    text-align: center;
  }

  &__loading {
    display: flex;
    justify-content: center;
    min-height: 200px;
  }
  &__carousel {
    margin-top: 30px;
    width: 100%;
    @media (min-width: 1025px) {
      margin-top: 50px;
    }
    .swiper-pagination {
      position: static;
      padding-top: 10px;
      @media (min-width: 1025px) {
        padding-top: 0;
      }
    }
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      opacity: 0.4;
      background: $black;
      vertical-align: middle;
      margin: 2px !important;
    }
    .swiper-pagination-bullet-active {
      background: $blue;
      width: 10px;
      height: 10px;
      opacity: 1;
    }
    .swiper-slide {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 428px;
    }
    .swiper-wrapper {
      row-gap: 20px;
      transition-timing-function: linear;
    }
  }
  @media (min-width: 1025px) {
    padding: 0 0 160px;
    &__carousel {
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 8%;
        height: fill-available;
        height: -webkit-fill-available;
        height: -moz-available;
        position: absolute;
        background: linear-gradient(90deg, $white 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 10;
        top: 0;
        left: 0;
      }
      &::after {
        content: '';
        display: block;
        width: 8%;
        height: fill-available;
        height: -webkit-fill-available;
        height: -moz-available;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, $white 100%);
        z-index: 10;
      }
    }
  }
}
