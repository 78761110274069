@import '../../assets/scss/variable.scss';

.contacts {
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px) {
      gap: 40px;
    }
  }
  &__section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (min-width: 768px) {
      gap: 15px;
    }
  }
  &__text-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__text {
    width: fit-content;
    opacity: 0.8;
    color: $black;
    &:hover {
      opacity: 1;
      color: $black;
    }
  }
}
