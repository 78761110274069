@import '../../assets/scss/variable.scss';

.review-modal {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(16, 24, 32, 0.3);
  z-index: 100;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    @media (min-width: 768px) {
      padding: 30px 40px;
      max-width: 657px;
      max-height: 473px;
      border: 1px solid rgba(16, 24, 32, 0.1);
      border-radius: 30px;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
  &__apartament-link {
    display: flex;
    column-gap: 10px;
    color: $black;
    transition: .3s;
    &:hover {
      transition: .3s;
      color: $black;
    }
  }
  &__apartament-image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  &__apartament-address {
    padding-top: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $blue;
  }
  &__raiting-wrap {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-top: 15px;
  }
  &__raiting {
    @media (min-width: 768px) {
    }
  }
  &__textarea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    width: 100%;
    max-height: 164px;
    height: 100%;
    border: 1px solid $dark-blue;
    border-radius: 10px;
    margin-top: 15px;
    resize: none;
    font-size: 14px;
    line-height: 16px;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 19px;
    }
    &::placeholder {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $black;
      opacity: 0.6;
      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 19px;
      }
    }
    @media (min-width: 768px) {
      max-height: 153px;
    }
  }
  &__title {
    text-align: center;
    margin-bottom: 25px;
    width: 100%;
    @media (min-width: 768px) {
      margin-bottom: 25px;
    }
  }
  &__buttons-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: auto;
    @media (min-width: 768px) {
      flex-direction: row-reverse;
      justify-content: center;
      margin-top: 30px;
    }
  }
  &__button {
    width: 100%;
    @media (min-width: 768px) {
      max-width: 200px;
    }
  }
  @media (min-width: 768px) {
  }
  &--open {
    display: block;
  }
}