.home-page {
  &__title {
    padding-bottom: 6px;
    @media (min-width: 1025px) {
      padding-bottom: 10px;
    }
  }
  &__sub-title {
    padding-bottom: 20px;
    @media (min-width: 1025px) {
      padding-bottom: 30px;
    }
  }
  &__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px;
    row-gap: 70px;
    @media (min-width: 1025px) {
      padding-top: 80px;
      padding-bottom: 160px;
      row-gap: 160px;
    }
  }
  &__serch-link-wrap {
    text-align: left;
    display: none;
    @media (min-width: 960px) {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 114px;
      padding: 10px 22px 0;
    }
  }
  &__serch-link {
    text-align: left;
  }
}