@import '../../assets/scss/variable.scss';

.carousel-detail {
  width: 100%;
  max-height: 200px;
  border-radius: 10px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-height: none;

  &__picture {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    aspect-ratio: 836 / 450;
    // object-fit: contain;
    object-position: center;
  }

  .swiper-pagination {
    bottom: 5px;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    opacity: 1;
    background: $white;
    vertical-align: middle;
    margin: 2px !important;
  }

  .swiper-pagination-bullet-active {
    background: $green;
    width: 10px;
    height: 10px;
    opacity: 1;
  }

  .swiper-slide {
    height: 100%;
  }

  &--mobile_modal {
    border-radius: 0;
    max-height: none;

    .carousel-detail__image {
      aspect-ratio: 1593/978;
    }

    .swiper-pagination {
      position: fixed;
    }

    .swiper-pagination-bullet {
      background: $gray;
      opacity: 0.1;
    }
    .swiper-pagination-bullet-active {
      background: $green;
      opacity: 1;
    }
  }

  &--desktop {
    max-height: 560px;
    width: auto;
  }

  &--thumbs.swiper {
    z-index: 0;
  }
  &--thumbs {
    width: 150px;
    max-height: 560px;
    flex-grow: 1;
    flex-shrink: 0;

    .swiper-slide {
      border-radius: 10px;
      height: 100px;
      overflow: hidden;
    }

    .swiper-slide-thumb-active {
      border: 3px solid $blue;

      .carousel-detail__image {
        height: calc(100% + 6px);
        width: calc(100% + 6px);
        margin-left: -3px;
        margin-top: -3px;
      }
    }
  }

  &__carousel-navigation {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    transform: translate(0, -50%);

    &--prev {
      left: 14px;
    }

    &--next {
      right: 14px;
    }
  }

  &__carousel-navigation-icon {
    stroke: $dark-blue;
    width: 20px;

    &--prev {
      transform: rotate(-180deg);
    }
  }

  &__carousel-navigation-wrap {
    position: relative;
    width: 100%;
  }

  &__carousel-desktop-wrap {
    display: flex;
    column-gap: 15px;
    z-index: 0;

    &--modal {
      max-height: none;
      .carousel-detail {
        aspect-ratio: 1593/924;
        &--thumbs {
          margin: 0;
          flex-grow: 0;
        }
        &--desktop {
          margin: 0;
          width: auto;
        }
      }
      @media (min-width: 800px) {
        max-width: 100%;
        // height: 100%;
        max-height: 100%;

        justify-content: space-evenly;
        .carousel-detail {
          max-height: none;
        }
      }
    }
  }

  @media (min-width: 540px) {
    max-height: 320px;

    &--mobile_modal {
      max-height: none;
    }

    &--mobile_modal {
      aspect-ratio: 1593/924;
    }
  }
  @media (min-width: 800px) {
    max-height: none;
    aspect-ratio: 836/450;
    &--mobile_modal {
      aspect-ratio: 1593/924;
    }
  }
}

.swiper,
.swiper-container {
  z-index: 0 !important;
}
