@import '../../assets/scss/variable.scss';

.side-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0px;
  top: 0;
  position: sticky;
  background: rgba(225, 237, 255, 0.5);
  border-radius: 20px;
  &__link {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    min-height: 40px;
    padding: 8.5px 20px;
    font-size: 20px;
    line-height: 23px;
    color: $black;
    &:hover {
      color: $blue;
    }
    &--active {
      color: $blue;
      border-left: 3px solid $blue;
    }
  }
}