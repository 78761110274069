@import '../../assets/scss/variable.scss';

.search-field {
  position: relative;
  width: 100%;

  &--calendar {
    width: calc((100% - 4px) / 2);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 8px 14px;
    min-height: 55px;
    background: $white;
    border: 1px solid $dark-blue;
    border-radius: 4px;
    width: 100%;
    height: 100%;

    @media (min-width: 960px) {
      border: none;
      border-radius: 0;
      min-height: 63px;
      padding: 8px 10px;
    }
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $black;
    opacity: 0.6;

    &--empty {
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__input {
    font-size: 18px;
    line-height: 21px;
    margin-top: 2px;
    max-width: 100%;

    &--hide {
      height: 0;
      margin-top: 0;
    }

    &--booking {
      margin: 0;
      font-size: 18px;
    }

    @media (min-width: 960px) {
      margin-top: 4px;

      &--hide {
        margin-top: 0;
      }

      &--booking {
        margin: 0;
        font-size: 18px;
      }
    }

    @media (min-width: 1280px) {
      margin-top: 4px;
      font-size: 20px;
      line-height: 23px;

      &--hide {
        margin-top: 0;
      }

      &--booking {
        margin: 0;
        font-size: 18px;
      }
    }
  }

  &__dot-separator {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: $black;
    vertical-align: middle;
    margin: 0 8px;
  }

  &__clear {
    width: 12px;
    height: 12px;
    opacity: 0.8;
    stroke: $black;
    position: absolute;
    right: 14px;
  }

  @media (min-width: 960px) {
    max-width: 110px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      max-width: calc((100% - 15px - 480px) / 2);
    }

    &:nth-child(5) {
      max-width: calc((100% - 15px - 480px) / 2);
    }

    &:first-child .search-field__inner {
      border-radius: 30px 0px 0px 30px;
      max-width: 100%;
    }

    &:nth-child(5) .search-field__inner {
      border-radius: 0px 30px 30px 0px;
      max-width: 100%;
    }
  }

  @media (min-width: 1024px) {
    max-width: 120px;

    &:first-child {
      max-width: calc((100% - 15px - 510px) / 2);
    }

    &:nth-child(5) {
      max-width: calc((100% - 15px - 510px) / 2);
    }
  }

  @media (min-width: 1280px) {
    max-width: 180px;

    &:first-child {
      max-width: calc((100% - 15px - 690px) / 2);
    }

    &:nth-child(5) {
      max-width: calc((100% - 15px - 690px) / 2);
    }
  }

  &--booking {
    max-width: 100%;
    .search-field__inner {
      padding: 10px 10px;
      border: 1px solid $dark-blue;
      border-radius: 10px;
      min-height: auto;
    }

    .search-field__title {
      display: none;

      &--empty {
        display: inline;
      }
    }

    &:first-child {
      max-width: 100%;
    }

    &:nth-child(5) {
      max-width: 100%;
    }

    @media (min-width: 960px) {
      .search-field__inner {
        padding: 10px 10px;
        border: 1px solid $dark-blue;
        border-radius: 10px;
        min-height: auto;
      }

      &:first-child .search-field__inner {
        border-radius: 10px;
      }

      &:nth-child(5) .search-field__inner {
        border-radius: 10px;
      }

      &.search-field--calendar {
        width: calc((100% - 4px) / 2);
      }

      &:first-child {
        max-width: 100%;
      }

      &:nth-child(5) {
        max-width: 100%;
      }
    }

    @media (min-width: 1024px) {
      max-width: 100%;

      &:first-child {
        max-width: 100%;
      }

      &:nth-child(5) {
        max-width: 100%;
      }
    }

    @media (min-width: 1280px) {
      max-width: 100%;

      &:first-child {
        max-width: 100%;
      }

      &:nth-child(5) {
        max-width: 100%;
      }
    }
  }
}
