@import '../../assets/scss/variable.scss';

.passport-data {
  &__user-data {
    position: relative;
    width: 100%;
    border: 1px solid #002d72;
    border-radius: 30px;
    padding: 15px;

    @media (min-width: 768px) {
      padding: 20px 30px;
    }
  }

  &__edit-button {
    position: relative;
    cursor: pointer;
    font-size: 19px;
    color: $blue;
    @media (min-width: 768px) {
      position: absolute;
      top: 20px;
      right: 30px;
    }
  }

  &__user {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (min-width: 768px) {
      gap: 30px;
    }
  }

  &__passport-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: 'Roboto';
  }

  &__placeholder-text {
    color: $gray;
  }

  &__passport-data {
    color: $black;
    font-weight: 600;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }

  &__field {
    @media (min-width: 960px) {
      text-align: right;
      flex-direction: row;
      align-items: center;
      column-gap: 30px;
      flex-wrap: wrap;
      .app-input__text,
      .radio-buttons__title {
        width: 100%;
        max-width: 284px;
        opacity: 0.4;
      }
      .app-input__field {
        width: 100%;
        max-width: calc(100% - 314px);
      }
      .app-input__error {
        margin-left: 314px;
      }
    }
  }
  &__checkbox {
    @media (min-width: 960px) {
      margin-left: 314px;
    }
  }
  &__button {
    width: 100%;

    @media (min-width: 768px) {
      max-width: 257px;
      margin-left: auto;
    }
  }
}
