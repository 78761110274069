@import '../../assets/scss/variable.scss';

.pagination {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
  max-width: 390px;
  &__btn {
    list-style: none;
    font-size: 16px;
    line-height: 19px;
    color: $dark-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: .3s;
    &:hover {
      transition: .3s;
      cursor: pointer;
      background: $light-blue;
    }
    &--active {
      color: $white;
      background: $dark-blue;
      &:hover {
        cursor: default;
        background: $dark-blue;
      }
    }
    &--disable {
      opacity: .4;
      &:hover {
        cursor: default;
        background: none;
      }
    }
  }
  &__arrow {
    stroke: $dark-blue;
    &--prev {
      transform: rotate(-180deg);
    }
  }
}