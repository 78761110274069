@import '../../../assets/scss/variable.scss';

.day {

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    width: 35px;
    height: 40px;
    font-size: 18px;

    &:hover {
        background-color: $light-blue;
        transition: ease-in-out 300ms;
    }

    &--active {
        color: $dark-blue;
        background-color: $light-blue;
    }

    &--disable {
        color: $gray;
        opacity: 0.4;
        cursor: default;

        &:hover {
            background-color: transparent;
            transition: ease-in-out 300ms;
        }

    }

    &--selected_date {
        background-color: $dark-blue;
        color: $white;

        &:hover {
            background-color: $dark-blue;
            transition: ease-in-out 300ms;
        }
    }

    &--selected_date {
        background-color: $dark-blue;
        color: $white;

        &:hover {
            background-color: $dark-blue;
            transition: ease-in-out 300ms;
        }
    }


    @media (max-width: 600px) {
        width: 35px;
        height: 40px;
        font-size: 18px;
    }

    @media (max-width: 600px) {
        width: 40px;
        height: 45px;
        font-size: 20px;
    }

    @media (min-width: 960px) {
        width: 45px;
        height: 50px;
        font-size: 24px;
    }

}