@import '../../assets/scss/variable.scss';

.tooltip {
  position: relative;
  line-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: .3s;
  &__text {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background: $white;
    border-radius: 10px;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    color: $black;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    bottom: calc(100% + 8px);
    &::after {
      content: '';
      border: 8px solid transparent;
      border-top: 7px solid $white;
      position: absolute;
      top: 100%;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
  }
  &:hover {
    transition: .3s;
    .tooltip__text {
      display: flex;
    }
  }
}