@import '../../assets/scss/variable.scss';

.footer-links {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  min-width: 245px;
  &__list-title {
    color: $white;
  }
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
    &--payment {
      flex-direction: row;
      column-gap: 10px;
    }
  }
  &__link {
    display: block;
    color: $white;
    opacity: .6;
    transition: .3s;
    &:hover {
      transition: .3s;
      color: $white;
      opacity: .8;
    }
    &--apps {
      opacity: 1;
      line-height: 0;
      &:hover {
        opacity: .7;
      }
    }
  }
  &__item {
    &--payment {
      background: $white;
      padding: 5.5px 8px;
      border-radius: 10px;
      width: 66px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: 1025px) {
        width: 100px;
        height: 50px;
        padding: 10px 15px;
      }
    }
  }
  &__image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    &--apps {
      max-height: 50px;
      @media (min-width: 1025px) {
        height: 40px;
      }
    }
  }
  &--payment {
    min-width: none;
  }
}