@import '../../assets/scss/variable.scss';

.messages {
  &__wrap {
    display: flex;
    flex-direction: column;
  }
  &__list {
    display: flex;
    flex-direction: column;
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 440px;
  }
  &__company-info {
    display: flex;
    flex-direction: column;
    opacity: 0.6;
    gap: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 20px 0px;
    width: 100%;
    border-bottom: 1px solid rgba(16, 24, 32, 0.2);
    gap: 10px;
    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }
  &__image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  &__preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }
  &__preview-title {
    display: flex;
    align-items: center;
    column-gap: 10px;
    row-gap: 4px;
    color: $black;
    @media (max-width: 1279px) {
      flex-wrap: wrap;
    }
  }
  &__preview-title-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__preview-title-info {
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__separator {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: $black;
    opacity: 0.6;
    border-radius: 50%;
    &:first-child {
      @media (max-width: 1279px) {
        display: none;
      }
    }
  }
  &__description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.6;
    color: $black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:first-child {
      overflow: visible;
    }
    @media (min-width: 1280px) {
      &:nth-child(2) {
        overflow: visible;
      }
    }
    &--message_prev {
      opacity: 0.4;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    gap: 15px;
  }
  &__date {
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
    color: $black;
    opacity: 0.6;
  }
  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    width: 24px;
    height: 24px;
    background: rgba(36, 76, 116, 0.1);
    border-radius: 30px;
    font-size: 14px;
    line-height: 16px;
    color: $dark-blue;
  }
  &__pagination {
    margin: 0 auto;
  }
  &__error {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 10px;
  }
}
